<template>
	<div
		class="modal"
		:class="loading ? 'loading' : ''"
	>
		<h3 class="modal__header">{{ empId ? 'Редактировать сотрудника' : 'Добавить сотрудника' }}</h3>
		<span class="modal__description">{{ !empId ? 'Для добавления сотрудника заполните форму ниже' : '' }}</span>
		<form @submit.prevent="addOrUpdateEmployee()">
			<div class="modal__row">
				<div class="modal__col">
					<div class="personal__wrapper">
						<input
							class="modal__field"
							:class="{['modal__field_error']:inputErrors.firstName}"
							type="text"
							placeholder="Имя"
							v-model="formFields.firstName"
						>
						<div
							class="modal__input_error_message"
							v-if="inputErrors.firstName"
						>{{ inputErrors.firstName }}
						</div>
					</div>
					<input
						class="modal__field"
						type="text"
						placeholder="Фамилия"
						v-model="formFields.lastName"
					>
					<input
						class="modal__field"
						type="text"
						placeholder="Отчество"
						v-model="formFields.secondName"
					>
					<div class="personal__wrapper">
						<PhoneInput
							class="modal__field"
							:class="{['modal__field_error']:inputErrors.phone}"
							v-model="formFields.phone"
							:placeholderText="'Введите телефон'"
						/>
						<div
							class="modal__field_checked"
							v-if="phoneUnique && !inputErrors.phone && formFields.phone!=''"
						/>
						<div
							class="modal__input_error_message"
							v-if="inputErrors.phone"
						>{{ inputErrors.phone }}
						</div>
					</div>
					<div class="personal__wrapper">
						<input
							class="modal__field"
							:class="{['modal__field_error']:inputErrors.email}"
							type="text"
							v-model="formFields.email"
							placeholder="Введите email"
						>
						<div
							class="modal__field_checked"
							v-if="emailUnique && !inputErrors.email"
						/>
						<div
							class="modal__input_error_message"
							v-if="inputErrors.email"
						>{{ inputErrors.email }}
						</div>
					</div>
				</div>
				<div class="modal__col">
					<vueDropzone
						@setPhoto="setPhoto"
						:existingPhoto="existingPhoto"
					>
						<div class="dropzone-custom-content">
							<h3 class="dropzone-custom-title">Перетащите файл с фото сюда или нажмите чтобы
								загрузить</h3>
							<div class="subtitle">максимальный размер файла не более 10мб</div>
						</div>
					</vueDropzone>
					<div class="personal__wrapper">
						<div
							class="modal__field"
							@click.prevent="showList = !showList"
						>{{ curJobLabel }}
						</div>
						<DropList
							:data="jobsList"
							v-if="showList"
							@change="update"
							class="personal__modal"
						/>
					</div>
					<div class="personal__wrapper">
						<input
							:type="showPassword ? 'text' : 'password'"
							class="modal__field"
							:class="{['modal__field_error']:inputErrors.password}"
							placeholder="Пароль"
							v-model="formFields.password"
						>
						<div
							class="modal__eye"
							:class="showPassword ? 'show' : ''"
							@click="showPassword = !showPassword"
						></div>
						<div
							class="modal__input_error_message"
							v-if="inputErrors.password"
						>{{ inputErrors.password }}
						</div>
					</div>
				</div>
			</div>
			<div
				class="modal__error-msg"
				v-if="errorMsg"
			>{{ errorMsg }}
			</div>
			<div
				class="modal__success-msg"
				v-if="successMsg"
			>{{ successMsg }}
			</div>
			<input
				class="modal__submit"
				:class="loading ? 'disabled' : ''"
				type="submit"
				:value="empId ? 'Сохранить изменения' : 'Добавить сотрудника'"
				:disabled="loading"
			>
		</form>
	</div>
</template>

<script>
import qs from 'qs';
import axios from 'axios';
import Validation from '@/mixins/validator.js';
import debounce from "lodash/debounce";
import {mapState} from "vuex";

export default {
	components: {
		vueDropzone: () => import('@/components/block/Dropzone'),
		DropList: () => import('@/components/block/Rest/DropList.vue'),
		PhoneInput: () => import('@/components/form/PhoneInput.vue')
	},
	mixins: [Validation],
	props: ['empId', 'jobsList'],
	data()
	{
		return {
			showList: false,
			showPassword: false,
			formFields: {
				firstName: '',
				secondName: '',
				lastName: '',
				phone: '',
				email: '',
				job: '',
				password: '',
				photo: false,
			},
			inputErrors: {
				firstName: '',
				phone: '',
				email: '',
				password: '',
			},
			curJobLabel: '',
			loading: false,
			errorMsg: false,
			successMsg: false,
			emailUnique: false,
			phoneUnique: false,
			existingPhoto: false,
		}
	},
	computed: {
		...mapState(['currentOwnerRestaurant']),
	},
	watch: {
		'formFields.phone': {
			handler: debounce(function ()
			{
				let error = this.formFields.phone == '' ? false : this.validatePhone(this.formFields.phone);
				if (error)
				{
					this.inputErrors.phone = error;
					return;
				}
				this.checkUnique('phone');
			}, 1000),
			deep: true
		},
		'formFields.email': {
			handler: debounce(function ()
			{
				let error = this.validateEmail(this.formFields.email);
				if (error)
				{
					this.inputErrors.email = error;
					return;
				}
				this.checkUnique('email');
			}, 1000),
			deep: true
		},
	},
	methods: {
		update(value)
		{
			this.formFields.job = value.value;
			this.curJobLabel = value.label;
			this.showList = false;
		},

		async checkUnique(fields)
		{
			let data = {};
			if (fields === 'phone' || fields === 'all')
			{
				this.phoneUnique = false;
				this.inputErrors.phone = false;
				data['phone'] = this.formFields.phone;
			}
			if (fields === 'email' || fields === 'all')
			{
				this.emailUnique = false;
				this.inputErrors.email = false;
				data['email'] = this.formFields.email;
			}
			if (!data['phone'] && !data['email'])
			{
				return false;
			}

			if (this.empId)
				data['id'] = this.empId;

			let result = await axios.post('/auth/checkPhoneOrEmailUnique/', qs.stringify(data));

			if (fields === 'phone' || fields === 'all')
			{
				if (result.data.phoneError)
					this.inputErrors.phone = result.data.phoneError;
				else
					this.phoneUnique = true;
			}
			if (fields === 'email' || fields === 'all')
			{
				if (result.data.emailError)
					this.inputErrors.email = result.data.emailError;
				else
					this.emailUnique = true;
			}
			if (result.data.error)
			{
				this.errorMsg = result.data.error;
			}

			if (this.errorMsg || this.inputErrors.phone || this.inputErrors.email)
				return false;

			return true;
		},

		async addOrUpdateEmployee()
		{
			this.loading = true;
			for (let i in this.inputErrors)
				this.inputErrors[i] = '';
			this.emailUnique = false;
			this.phoneUnique = false;
			this.successMsg = false;
			this.errorMsg = false;

			let valid = await this.validateFields();
			if (!valid)
			{
				this.loading = false;
				return false;
			}

			let formData = new FormData();
			formData.append("id", this.empId);
			formData.append("currentOwnerRestaurant", this.currentOwnerRestaurant);
			for (let i in this.formFields)
			{
				if (i === 'photo' && this.formFields[i].manuallyAdded)
					formData.append('manuallyAddedPhoto', true);
				else
					formData.append(i, this.formFields[i]);
			}

			let result = await axios.post('/lk/addOrEditEmployee/', formData, {headers: {'Content-Type': 'multipart/form-data'}});
			if (result.data.success)
			{
				this.successMsg = this.empId ? 'Изменения успешно сохранены' : 'Сотрудник успешно добавлен';
				this.$emit('updateList');
			} else
			{
				this.errorMsg = result.data.error;
			}
			this.loading = false;
		},

		async validateFields()
		{
			let noErrors = true;

			if (!this.formFields.firstName)
			{
				this.inputErrors.firstName = 'Обязательное поле';
				noErrors = false;
			}

			let error = this.validateEmail(this.formFields.email);
			if (error)
			{
				this.inputErrors.email = error;
				noErrors = false;
			}

			error = this.formFields.phone == '' ? false : this.validatePhone(this.formFields.phone);
			if (error)
			{
				this.inputErrors.phone = error;
				noErrors = false;
			}

			if (this.empId && this.formFields.password !== '' && this.formFields.password.length < 6 || !this.empId && this.formFields.password.length < 6)
			{
				this.inputErrors.password = 'Минимум 6 символов';
				noErrors = false;
			}

			let param = false;
			if (!this.inputErrors.phone && !this.inputErrors.email)
				param = 'all';
			else if (!this.inputErrors.email)
				param = 'email';
			else if (!this.inputErrors.phone)
				param = 'phone';

			if (param)
			{
				let result = await this.checkUnique(param);
				if (!result)
				{
					noErrors = false;
				}
			}

			return noErrors;
		},
		setPhoto(file)
		{
			this.formFields.photo = file;
		},
		async getEmployeeInfo()
		{
			this.loading = true;
			let data = qs.stringify({
				id: this.empId,
				currentOwnerRestaurant: this.currentOwnerRestaurant
			});
			let result = await axios.post('/lk/getEmployeeInfo/', data);
			if (result.data.success)
			{
				for (let i in result.data.userInfo)
				{
					if (i === 'photo')
						this.existingPhoto = result.data.userInfo[i];
					else
						this.formFields[i] = result.data.userInfo[i] ? result.data.userInfo[i] : '';
				}
				let idx = this.jobsList.findIndex(el =>
				{
					return el.value == this.formFields.job
				})
				this.curJobLabel = this.jobsList[idx].label;
			} else
			{
				this.errorMsg = result.data.error;
			}
			this.loading = false;
		},
	},
	mounted()
	{
		if (this.jobsList)
		{
			this.formFields.job = this.jobsList[0].value;
			this.curJobLabel = this.jobsList[0].label;
		}
		if (this.empId > 0)
		{
			this.getEmployeeInfo();
		}
	}
}
</script>

<style lang="scss">
$black: #3D4248;
$green: #66BE6D;
$grey: #979797;
$lightgray: #F8F8F8;

.personal
{
	&__wrapper
	{
		position: relative;
	}

	&__modal
	{
		position: absolute;
		z-index: 10000;
		top: 60px;
		width: 100%;
		transition: background-color .2s ease-in-out;

		& .modal
		{
			padding: 10px 0 !important;
			border-radius: 8px !important;

			& span
			{
				width: 100%;
				padding: 11px 24px;
				font-size: 16px;

				&:hover
				{
					background: $lightgray;
				}
			}
		}
	}
}

.modal
{
	&.loading
	{
		opacity: 0.5;
	}

	&__error-msg
	{
		color: red;
		width: 100%;
		text-align: center;
		margin-bottom: 15px;
	}

	&__success-msg
	{
		color: $green;
		width: 100%;
		text-align: center;
		margin-bottom: 15px;
	}

	&__input_error_message
	{
		color: red;
		font-size: 11px;
		position: absolute;
		top: 24px;
		right: 7px;
	}

	&__row
	{
		display: flex;
		flex-basis: 670px;
		max-width: 670px;
		justify-content: space-between;
	}

	&__col
	{
		flex: 0 0 314px;
	}

	&__header
	{
		text-align: center;
		color: $black;
		font-size: 28px;
		font-weight: 700;
		margin-bottom: 10px;
	}

	&__description
	{
		font-size: 16px;
		color: $grey;
		display: block;
		text-align: center;
		margin-bottom: 32px;
	}

	&__field
	{
		background: #F8F8F8;
		border-radius: 4px;
		border: 1px solid #F0F0F0;
		padding: 12px 24px;
		width: 100%;
		font-size: 16px;
		margin-bottom: 24px;
		position: relative;
		max-height: 43px;
		line-height: 1.15;

		&::placeholder
		{
			color: $grey;
		}

		&_error
		{
			border: 1px solid red;
		}

		&_checked
		{
			&:after
			{
				content: '';
				background: url("~@/assets/img/checkmark-green.svg");
				height: 20px;
				width: 20px;
				background-size: cover;
				position: absolute;
				right: 15px;
				top: 24px;
				transform: translateY(-50%);
			}
		}
	}

	&__submit
	{
		all: unset;
		margin-right: 32px;
		padding: 14px 24px;
		color: white;
		font-size: 14px;
		font-weight: 700;
		background: $green;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: 622px;
		width: 100%;
		cursor: pointer;

		&.disabled
		{
			background: $grey;
		}
	}

	&__eye
	{

		position: absolute;
		top: 22px;
		right: 15px;
		cursor: pointer;
		mask: url('~@/assets/img/Personal/eye.svg') no-repeat center;
		background: $greyLightText;
		transform: translateY(-50%);
		height: 18px;
		width: 24px;

		&.show
		{
			background: $green;
		}
	}
}
</style>